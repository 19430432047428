@keyframes rotateRight {
  from {
    opacity: 0;
    transform: rotate(10deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}

.animate-rotateRight {
  animation: rotateRight 0.8s ease-out;
}
